<template>
<div class="blog_container">

    <div v-if="Master" class="my-6">
        <v-select :items="months" outlined label="月別" v-model="month" />
    </div>

    <div class="mx-auto blog_card  pb-3 py-8 rounted relative my-8" v-for="(blog,i) in blogs" :key="i">
        <v-btn v-if="Master" class="absolute t-0 l-0 z-1" @click="deletePhoto(blog.id)">削除</v-btn>
            <v-icon class="roope absolute noevent z-1" v-if="blog.photo1">fas fa-search-plus</v-icon>
        <div class="photos_box rounded relative-center" v-if="blog.photo1">
            <img :src="url+blog.photo1" class=" float-left photo" :class="{imgsize1:blog.photo4,imgsize2:!blog.photo4&&blog.photo2,imgsize3:!blog.photo2}" @click="dialogOpen(url+blog.photo1)" v-if="blog.photo1" />
            <img :src="url+blog.photo2" class=" float-left photo" :class="{imgsize1:blog.photo4||blog.photo3,imgsize2:!blog.photo3&&!blog.photo4}" @click="dialogOpen(url+blog.photo2)" v-if="blog.photo2" />
            <img :src="url+blog.photo3" class=" float-left imgsize1 photo" @click="dialogOpen(url+blog.photo3)" v-if="blog.photo3" />
            <img :src="url+blog.photo4" class=" float-left imgsize1 photo" @click="dialogOpen(url+blog.photo4)" v-if="blog.photo4" />
            <div class="clear"></div>
        </div>        
        <div class="blog_text relative-center mt-8" ref="text">
            <h2 class="text-center mb-4 f-12">{{blog.title}}</h2>
            <div class="f-08 f-gray text-right" v-text="' @'+blog.created_at.substr(0,16)" />
            <p v-text="blog.text" class="f-12"></p>
            <div class="text-right">

            </div>
            <v-btn class="absolute text_btn brown darken-4" dark large block @click="open(i)" v-if="btns[i]">表示する</v-btn>
        </div>
    </div>
    <infinite-loading @infinite="infiniteHandler" spinner="spiral" v-if="count" :distance="3000">
        <div slot="spinner"></div>
        <div slot="no-more"></div>
        <div slot="no-results"></div>
    </infinite-loading>
    <v-dialog v-model="dialog" width="1000">
        <img :src="img" class="width-100 dialog_photo" v-if="img" />
    </v-dialog>
</div>
</template>

<script>
import Vue from 'vue'
import axios from "axios"
import d from "@/d"
import moment from "moment";
export default {
    data() {
        return {
            blogs: [],
            btns: [],
            img: "",
            dialog: false,
            start: 0,
            end: 20,
            count: 0,
            load: false,
            months: [],
            month: "",
            url: '../storage/imgs/',
        }
    },
    props: {
        Master: Boolean
    },
    async mounted() {

        const response = await axios.post('/master/blog')
        this.blogs = response.data.blogs
        this.setBtn(0)

        let blogCount = response.data.blogs.map(item => item.created_at);

        blogCount = blogCount.map(function (a) {
            return moment(a).format("yyyy-MM");
        });

        if (this.Master) {
            let months = []

            for (var i = 0; i < blogCount.length; i++) {
                var elm = blogCount[i];
                months[elm] = (months[elm] || 0) + 1;
            }

            this.months = Object.keys(months)
            this.months.unshift('');

        }
        this.count = response.data.count

    },
    methods: {
        async setBtn(val) {
            await d.sleep(50)
            for (let i = val; i < this.$refs.text.length; i++) {
                if (this.$refs.text[i].clientHeight > 300) {
                    this.$refs.text[i].style.height = "300px"
                    Vue.set(this.btns, i, true)
                } else {
                    Vue.set(this.btns, i, false)
                }
            }
        },
        open(val) {
            this.$refs.text[val].style.height = "auto"
            Vue.set(this.btns, val, false)
        },
        dialogOpen(val) {
            this.img = val
            this.dialog = true
        },
        async infiniteHandler($state) {
            if (!this.load) {
                if (this.end > this.count) {
                    // 表示するデータが無くなった場合
                    $state.complete()
                } else {
                    // 表示するデータがある場合

                    await this.getData()
                    $state.loaded();
                }
            }

        },
        async getData() {
            this.load = true
            const data = {
                offset: this.start + 20,
                month: moment(this.month).format("MM"),
                year: moment(this.month).format("YYYY")
            }
            const response = await axios.post('/master/blog', data)
            this.blogs.push(...response.data.blogs)
            this.start = this.start + 20
            this.end = this.end + 20
            this.setBtn(this.start)
            this.load = false
        },
        async deletePhoto(val) {

            let check = window.confirm("本当に削除しますか")

            if (!check) {
                return false
            }

            const data = {
                id: val
            }
            await axios.post('/master/blog_delete', data)

            this.blogs.splice(this.blogs.findIndex(({
                id
            }) => id === val), 1)
        }
    },
    watch: {
        async month() {
            this.blogs = []
            const data = {
                month: moment(this.month).format("MM"),
                year: moment(this.month).format("YYYY")
            }
            const response = await axios.post('/master/blog', data)
            this.start = 0
            this.end = 20
            this.blogs = response.data.blogs
            this.count = response.data.count
            this.setBtn(0)
            await d.sleep(50)
            this.load = false
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/blog.scss";
</style>
